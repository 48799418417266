import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
import MakeConfessionPage from "../views/MakeConfessionPage.vue";
import ReadConfessionPage from "../views/ReadConfessionPage.vue";
import PaymentPage from "@/views/PaymentPage.vue";
import PaymentSuccessPage from "@/views/PaymentSuccessPage.vue";
import ConfessionSuccessPage from "@/views/ConfessionSuccessPage.vue";
import FAQPage from "@/views/FAQPage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: LandingPage,
    meta: {
      title: "How it works",
      metaTags: [
        {
          name: "description",
          content:
            "Confession Box lets you share your thoughts and secrets anonymously. Express yourself freely and safely.",
        },
        {
          name: "keywords",
          content: "confession, anonymous, share secrets, express yourself",
        },
      ],
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQPage,
    meta: {
      title: "Frequently Asked Questions",
      metaTags: [
        {
          name: "description",
          content:
            "Frequently asked questions about Confession Box, an anonymous platform to share your confessions safely.",
        },
        {
          name: "keywords",
          content:
            "Confession Box, FAQ, anonymous confessions, share secrets, express yourself",
        },
      ],
    },
  },
  {
    path: "/payment",
    name: "PaymentPage",
    component: PaymentPage,
    meta: {
      title: "Payment",
      metaTags: [
        {
          name: "description",
          content: "Secure payment form to purchase tokens on Confession Box.",
        },
        {
          name: "keywords",
          content: "Confession Box, payment, tokens, purchase, secure payment",
        },
      ],
    },
  },
  {
    path: "/payment-success",
    name: "PaymentSuccessPage",
    component: PaymentSuccessPage,
    meta: {
      title: "Payment Success",
      metaTags: [
        {
          name: "description",
          content:
            "Your payment was successful. Access your tokens on Confession Box.",
        },
        {
          name: "keywords",
          content: "Confession Box, payment successful, tokens, access",
        },
      ],
    },
  },
  {
    path: "/confess",
    name: "Confess",
    component: MakeConfessionPage,
    meta: {
      title: "Confess",
      metaTags: [
        {
          name: "description",
          content:
            "Share your thoughts anonymously on Confession Box. Create a new confession safely and securely.",
        },
        {
          name: "keywords",
          content:
            "Confession Box, create confession, anonymous, share secrets, express yourself",
        },
      ],
    },
  },
  {
    path: "/confess-success",
    name: "ConfessSuccess",
    component: ConfessionSuccessPage,
    meta: {
      title: "Confession Successfully Submitted",
      metaTags: [
        {
          name: "description",
          content:
            "Your confession has been successfully submitted on Confession Box.",
        },
        {
          name: "keywords",
          content:
            "Confession Box, confession submitted, anonymous, share secrets",
        },
      ],
    },
  },
  {
    path: "/read-confession",
    name: "ReadConfessionPage",
    component: ReadConfessionPage,
    meta: {
      title: "Read a Confession",
      metaTags: [
        {
          name: "description",
          content:
            "Read anonymous confessions from others on Confession Box. Explore random secrets shared by people.",
        },
        {
          name: "keywords",
          content:
            "Confession Box, random confession, anonymous, share secrets, explore",
        },
      ],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Update meta tags on route change
router.beforeEach((to, from, next) => {
  // Update document title with suffix
  document.title = to.meta.title
    ? `${to.meta.title} - Confession Box`
    : "Confession Box";

  // Remove previously added meta tags (only those added by router)
  document
    .querySelectorAll("meta[data-vue-router]")
    .forEach((tag) => tag.remove());

  // Append new meta tags if set on route
  if (to.meta.metaTags) {
    to.meta.metaTags.forEach((tag) => {
      const meta = document.createElement("meta");
      Object.keys(tag).forEach((key) => {
        meta.setAttribute(key, tag[key]);
      });
      // Mark meta tag so that it can be removed next time
      meta.setAttribute("data-vue-router", "true");
      document.head.appendChild(meta);
    });
  }

  next();
});

export default router;
